@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: red;
  }
  50% {
    transform: scale(1.2);
    background-color: #ffffff;
  }
  100% {
    transform: scale(1);
    background-color: red;
  }
}

.red-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  animation: pulse 2s infinite ease-in-out; /* Adjust the duration and timing function as needed */
}
